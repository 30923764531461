<template>
<div>
  <el-card shadow="never" class="box" v-indentify="'dashboard:view:index-order'">
    <div slot="header">
      <span class="list-header">订单数量</span>
    </div>
    <el-form :inline="true" :model="orderForm" class="demo-form-inline" @submit.native.prevent>
      <el-form-item label="咨询时间">
        <el-date-picker
            v-model="date"
            value-format="yyyy-MM-dd"
            type="daterange"
            @change="dateHandle"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryOrder">查询</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="20">
      <el-col :span="8" v-for="(item, index) in orderTotal" :key="index">
        <div class="col-content">
          <el-card shadow="always">
            <div class="order-total" :class="[`order-total-${index}`]" :data-title="item.title">
              <div>订单总数：{{item.order_counts}}</div>
              <div>订单总金额：{{item.order_amounts}}元</div>
              <div>服务时长：{{item.service_time}}分钟</div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </el-card>
</div>
</template>

<script>
import { indexOrderApi } from '@/api'

export default {
  name: 'PcDashboardView',
  data () {
    return {
      date: [],
      orderForm: {
        start_time: '',
        end_time: ''
      },
      orderTotal: [],
      orderDataTitle: {
        total: '总数量',
        pay: '已支付',
        not_pay: '未支付'
      }
    }
  },
  mounted () {
    this.queryOrder()
  },
  methods: {
    /**
     * 时间区间change
     * @param date
     */
    dateHandle (date) {
      if (date) {
        this.orderForm.start_time = date[0]
        this.orderForm.end_time = date[1]
      } else {
        this.orderForm.start_time = ''
        this.orderForm.end_time = ''
      }
    },
    async queryOrder () {
      const { orderDataTitle } = this.$data
      const data = await indexOrderApi(this.orderForm)
      if (data.code === 1) {
        const orderTotal = []
        const orderData = data.data
        for (const orderKey in orderData) {
          orderTotal.push({ title: orderDataTitle[orderKey], ...orderData[orderKey] })
        }
        this.orderTotal = orderTotal
      }
    }
  }
}
</script>

<style scoped lang="scss">
.col-content {
  width: 100%;
  padding-top: 25px;
  margin-bottom: 12px;
}

.order-total {
  line-height: 1.5;
  position: relative;
  &:before {
    height: 100%;
    content: attr(data-title);
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 30px;
    background: linear-gradient(90deg,#41aaff,#003cff);
    -webkit-background-clip: text;
    color: transparent;
  }
  &.order-total-1 {
    &:before {
      background: linear-gradient(90deg, #93d58e, #36cc0b);
      -webkit-background-clip: text;
    }
  }
  &.order-total-2 {
    &:before {
      background: linear-gradient(90deg, #afb6bd, #8a97c0);
      -webkit-background-clip: text;
    }
  }
}
</style>
